import ApiBase from './ApiBase'
import apiUrl from '../config/api'
import {RequestResult} from '@/utils/interface'


// response
export interface EmployeeResponse {
    username: string
    nickname: string
    headimgurl: string
}

export interface BindEmployeeParams {
    mobile: string
    code: number
    is_reception: number
}

class ApiEmployee extends ApiBase {
    protected url: string = apiUrl.hClean + '/delivery';
    protected prefix: string = ''
    protected resultDataKey: string = 'data';

    public index(data = {}): Promise<RequestResult<any>> {
        return this.post('/staff/list', data)
    }

    public bind(data: BindEmployeeParams): Promise<RequestResult<any>> {
        return this.post('/staff/bind', data)
    }

    public untie(data: { mobile: string }): Promise<RequestResult<any>> {
        return this.post('/staff/untie', data)
    }


}

export const apiEmployee = new ApiEmployee()

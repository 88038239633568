











































import {Component, Vue} from "vue-property-decorator";
import MyFooter from "@/components/MyFooter.vue";
import {ISms, Sms} from '@/utils/Sms'
import {apiEmployee, BindEmployeeParams} from "@API/ApiEmployee";

@Component({
  name: "EmployeeAdd",
  components: {MyFooter},
})
export default class TabFour extends Vue {
  private sms: ISms = new Sms()
  protected form = {
    mobile: '',
    code: '',
    is_reception: 0
  }

  created() {
  }

  handleSendSms() {
    this.sms.sendSms(this.form.mobile, 'add-employee')
  }

  onSubmit(e: BindEmployeeParams) {
    const loading = this.$toast.loading({
      message: '加载中...',
      duration: 0,
      mask: true
    })

    apiEmployee.bind({
      mobile: e.mobile,
      code: e.code,
      is_reception: 1
    })
        .finally(() => {
          loading.clear()
        })
        .then(res => {
          this.$toast.success({
            message: '添加成功',
            onClose: () => {
              this.$router.go(-1)
            }
          })
        })
        .catch(e => {
          this.$dialog({
            title: '添加失败',
            message: e.message
          })
        })
  }


}
